<template>
  <div class="church-ministry template-1">
    <page-header class="mx-lg">
      <h1>{{ translations.tcStateHome }}</h1>
    </page-header>
    <camp-selector @camp_changed="page_load($event)" :showCamp="false" :tooltip="translations.tcCurrentHome"
      :i18n="translations.components" />
    <div class="page-body container mx-med">
      <section class="section-1">
        <h4>{{ translations.tcUpcomingStateEvent }}</h4>
        <div class="row">
          <div class="col col-6" v-if="translations.components">
            <DateCard v-for="(visit, index) in visits" :key="index" :obj="visit.date" :i18n="translations.common">
              <BasicDateCard :i18n="translations.components" :obj="{
                title: visit.title,
                description: visit.description,
                schedule: visit.schedule,
                destination: visit.destination,
                meetingDetails: visit.meetingDetails,
              }" @date_card_clicked="handleDateCardClick()" />
            </DateCard>
            <div class="mt-3">
              <b-button @click="handleEventViewMoreClick" variant="primary" class="more">
                {{ translations.tcViewMore }}
              </b-button>
            </div>
          </div>
          <div class="col col-6">
            <b-skeleton-wrapper :loading="loading.event">
              <template #loading>
                <b-card>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                </b-card>
              </template>
              <NumberCard v-for="(stat, index) in topWidgetData" :key="index" :obj="stat"
                :i18n="translations.components" />
            </b-skeleton-wrapper>
          </div>
        </div>
      </section>
      <section class="stats statelanding">
        <div class="container">
          <b-skeleton-wrapper :loading="loading.stat" class="mb-4">
            <template #loading>
              <b-card>
                <b-skeleton width="85%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
                <b-skeleton width="85%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
              </b-card>
            </template>
            <div class="row">
              <div class="col" v-for="(stat, index) in stats" :key="index">
                <app-graph :stat="stat"></app-graph>
              </div>
            </div>
            <b-button @click="handleStatsViewMoreClick" variant="primary" class="more">
              {{ translations.tcViewMore }}
            </b-button>
          </b-skeleton-wrapper>
        </div>
      </section>
      <section class="announcements">
        <div class="container">
          <div class="row">
            <div class="card col">
              <h4>{{ translations.tcAnnouncements }}</h4>
              <div class="ann-text" v-html="announcementAll.msg_message"></div>
              <div class="row btn-row">
                <b-button @click="handleAnnouncementsViewMoreClick" variant="secondary">
                  {{ translations.tcSeeMore }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="page-body container mx-med pt-3">
      <section class="section-3">
        <div class="row">
          <InfoCard v-for="(info, index) in tiles" :key="index" :obj="info" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BasicDateCard from '@/components/date-card/Basic.vue'
import { calendarMixin } from '@/mixins/calendarMixin'
import calendarService from '../../../services/calendarService'
import CampSelect from '@/components/CampSelect.vue'
import constantData from '@/json/data.json'
import DateCard from '@/components/date-card/DateCard.vue'
import Event from '@/components/Event.vue'
import Graph from '@/components/Graph.vue'
import InfoCard from '@/components/info-card/InfoCard.vue'
import moment from 'moment'
import NumberCard from '@/components/number-card/NumberCard.vue'
import { numericMixin } from '@/mixins/numericMixin'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { securityMixin } from '@/mixins/securityMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'state-landing-page',
  mixins: [calendarMixin, numericMixin, securityMixin, translationMixin],
  data() {
    return {
      translations: {},
      yearataglance: null,
      stats: [],
      visits: [],
      dataincircle: [],
      secured_controls: {
        state_treasury_tools_tile: 'cbd9c0ee-42f8-492f-be0f-06bcc9f0b01c',
        state_goal_planner_tile: '5222f96a-3d64-4333-9e33-805f95bbe092',
        state_security_manager_tile: 'd0677692-759a-4464-8933-4ce2b67785c2',
        state_secure_reports_tile: '4d40bad2-a6b2-4a15-a038-d895deede5e6',
      },
      loading: {
        event: false,
        stat: false,
      },
      topWidgetData: [
        {
          num: 0,
          title: 'PERCENTAGE OF CHURCHES VISITED PAST 12 MONTHS',
          subtitle: '',
        },
      ],
      churchMinistryInfo: [
        {
          display: false,
          class: 'col col-6',
          title: 'STATE TREASURY TOOLS',
          content: 'Submit remittances, view remittance history, update state banking details,etc.',
          link: '/officers/camp/treasury-tools/s',
          label: 'View More',
          img: {
            src: 'svgs/applications-icon.svg',
            alt: 'Hand Praying',
            width: 47,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-6',
          title: 'STATE GOAL PLANNER',
          content: 'Set and review state goals for receipts, Scripture distributions, etc.',
          link: '/officers/camp/goal-planner/st',
          label: 'View More',
          img: {
            src: 'svgs/church-reports.svg',
            alt: 'Hand Praying',
            width: 47,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-6',
          title: 'WEBSITE SECURITY MANAGER',
          content: 'Update website security access for members of your state.',
          link: '/officers/page/officers-security-manager',
          label: 'View More',
          img: {
            src: 'svgs/security-icon.svg',
            alt: 'Hand Praying',
            width: 47,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-6',
          title: 'STATE PRAYER CENTER',
          content: 'View a list of prayer requests for your state',
          link: '/programs/pw/prayer-center-daily',
          label: 'View More',
          img: {
            src: 'svgs/hands-praying.svg',
            alt: 'Hand Praying',
            width: 47,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-6',
          title: 'MEMBER LIST',
          content: 'View a list of all members in your state association.',
          link: '/officers/directory/members-list',
          label: 'View More',
          img: {
            src: 'svgs/member-icon.svg',
            alt: 'Hand Praying',
            width: 47,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-6',
          title: 'OTHER STATE LISTS',
          content: 'View a list of camps, speakers,officers, etc. in your state association',
          link: '/officers/state/state-directory',
          label: 'View More',
          img: {
            src: 'svgs/church-list.svg',
            alt: 'Hand Praying',
            width: 47,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-6',
          title: 'SECURE REPORTS',
          content: 'Run receipt, placement, member and other reports for your state, area, etc.',
          link: '/reports/report-center/sr',
          label: 'View More',
          img: {
            src: 'svgs/church-reports.svg',
            alt: 'Hand Praying',
            width: 47,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-6',
          title: 'OFFICER RESOURCES',
          content: 'View manuals, videos, and other resources for state officers.',
          link: '/resources/media-portal',
          label: 'View More',
          img: {
            src: 'svgs/program-resources.svg',
            alt: 'Hand Praying',
            width: 47,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-6',
          title: 'MINISTRY TRAINING LIBRARY',
          content: 'Online learning resources to equip members for ministry.',
          extlink: 'https://theconnection.gideons.org/Media/microsites/launch/story.html',
          label: 'View More',
          img: {
            src: 'svgs/program-resources.svg',
            alt: 'Resources',
            width: 47,
            height: 64,
          },
        },
      ],
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('basic-date-card', 'camp-select', 'date-card', 'number-card', 'security-banner'),
        this.getComponentTranslations(
          'common.attire',
          'common.church-service-type',
          'common.days',
          'common.months-abbreviations'
        ),
      ]).then((results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
        this.loadTranslations()
      })
      await this.page_load()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      getAnnouncementMessages: 'announcements/getAnnouncementForState',
      getChurchVisited: 'officersModule/getChurchVisited',
      getControlPermissionsFromList: 'user/getControlPermissionsFromList',
      loadStateDashboardDate: 'dashboard/loadStateDashboard',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load(evt) {
      if (!evt) {
        this.loading.event = true
        this.loading.stat = true
        let statedata = await this.loadStateDashboardDate()
        await this.checkPermissions()
        await this.getAnnouncementMessages(this.officerToolbarSelected.country_state)
        await this.loadMeetings()
          .then(() => {
            this.loading.event = false
          })
          .catch((err) => { console.error(err); })
        let church_visited = await this.getChurchVisited()
          .then()
          .catch((err) => { console.error(err); })
        this.yearataglance = statedata
        if (this.dataincircle.length > 0) this.dataincircle = []
        this.yearataglance.yearataglance.forEach((item) => {
          if (item.Stat_Level === 'S') {
            this.dataincircle.push(item)
          }
        })
        if (church_visited.count !== null) {
          this.topWidgetData = [
            {
              num: Math.round((church_visited.count[0].subtotal / church_visited.count[0].total) * 100) + '%',
              title:
                this.translations.tcPercentageOfChurchesVistedPast12Months ||
                'PERCENTAGE OF CHURCHES VISITED PAST 12 MONTHS',
              subtitle: '',
            },
          ]
        }
        this.generateStats(this.dataincircle)
      }
    },
    async checkPermissions() {
      await this.getControlPermissionsFromList(Object.values(this.secured_controls)).then(() => {
        this.churchMinistryInfo[0].display = this.treasuryIsOpen && this.determineAccessibility(
          this.secured_controls.state_treasury_tools_tile
        )
        this.churchMinistryInfo[1].display = this.determineAccessibility(this.secured_controls.state_goal_planner_tile)
        this.churchMinistryInfo[2].display = this.determineAccessibility(
          this.secured_controls.state_security_manager_tile
        )
        this.churchMinistryInfo[6].display = this.determineAccessibility(
          this.secured_controls.state_secure_reports_tile
        )
      })
    },
    handleAnnouncementsViewMoreClick() {
      this.$router.push({ name: 'announcements' })
    },
    handleEventViewMoreClick() {
      this.$router.push({ name: 'state-event-grid' })
    },
    handleStatsViewMoreClick() {
      this.$router.push({ name: 'camp-dashboard', hash: '#yearataglance' })
    },
    handleDateCardClick() { },
    async loadMeetings() {
      let meetings = await calendarService.getStateMeetings(this.officerToolbarSelected.country_state, null, null)
      this.visits = meetings.data.meetings.slice(0, 3).map((item) => {
        let check = moment(item.mtg_start_date, 'YYYY/MM/DD')
        return {
          title: item.mtg_title,
          description: item.vmrt_trn_txt,
          schedule: check.format('dddd'),
          destination:
            item.mtg_mtt_key && item.mtg_mtt_key == constantData.meetings.stateconvention.value
              ? constantData.meetings.stateconvention.route
              : '/calendar/state/event/event-detail',
          date: {
            month: check.format('MMM'),
            day: check.format('D'),
            year: check.format('YYYY'),
          },
          meetingDetails: this.meetingDetails(item),
        }
      })
    },
    generateStats(obj) {
      let statsList = []
      let sumYTD = 0
      if (obj !== null) {
        obj.forEach((item) => {
          if (item.WSType === 'NM' || item.WSType === 'AM') {
            sumYTD += item.YTD
          }
        })
        let members = {
          title: 'NEW MEMBERS',
          data: {
            current: sumYTD,
            full: sumYTD,
            after: '',
            before: '',
          },
        }
        statsList.push(members)
      } else {
        let members = {
          title: 'NEW MEMBERS',
          data: {
            current: 0,
            full: 0,
            after: '',
            before: '',
          },
        }
        statsList.push(members)
      }
      obj.forEach((item) => {
        if (item.WSType === 'SP' || item.WSType === 'SR') {
          statsList.push({
            title: item.WSType === 'SP' ? 'SCRIPTURE ORDERS' : 'SCRIPTURE RECEIPTS',
            data: {
              current: item.YTD,
              full: !!item.GTD && item.GTD > 0 ? item.GTD : item.YTD,
              after: '',
              before: '',
            },
          })
        }
      })
      this.stats = statsList
      this.loading.stat = false
    },
    loadTranslations() {
      this.topWidgetData[0].title = this.translations.tcPercentageOfChurchesVistedPast12Months
      this.churchMinistryInfo[0].title = this.translations.tcStateTreasuryToolsTitle
      this.churchMinistryInfo[0].content = this.translations.tcStateTreasuryToolsTitle
      this.churchMinistryInfo[0].label = this.translations.tcViewMore
      this.churchMinistryInfo[0].img.alt = this.translations.tcHandPraying

      this.churchMinistryInfo[1].title = this.translations.tcStateGoalPlannerTitle
      this.churchMinistryInfo[1].content = this.translations.tcStateGoalPlannerContent
      this.churchMinistryInfo[1].label = this.translations.tcViewMore
      this.churchMinistryInfo[1].img.alt = this.translations.tcHandPraying

      this.churchMinistryInfo[2].title = this.translations.tcWebsiteSecurityManagerTitle
      this.churchMinistryInfo[2].content = this.translations.tcWebsiteSecurityManagerContent
      this.churchMinistryInfo[2].label = this.translations.tcViewMore
      this.churchMinistryInfo[2].img.alt = this.translations.tcHandPraying

      this.churchMinistryInfo[3].title = this.translations.tcStatePrayerCenterTitle
      this.churchMinistryInfo[3].content = this.translations.tcStatePrayerCenterContent
      this.churchMinistryInfo[3].label = this.translations.tcViewMore
      this.churchMinistryInfo[3].img.alt = this.translations.tcHandPraying

      this.churchMinistryInfo[4].title = this.translations.tcMemberListTitle
      this.churchMinistryInfo[4].content = this.translations.tcMemberListContent
      this.churchMinistryInfo[4].label = this.translations.tcViewMore
      this.churchMinistryInfo[4].img.alt = this.translations.tcHandPraying

      this.churchMinistryInfo[5].title = this.translations.tcOtherStateListsTitle
      this.churchMinistryInfo[5].content = this.translations.tcOtherStateListsContent
      this.churchMinistryInfo[5].label = this.translations.tcViewMore
      this.churchMinistryInfo[5].img.alt = this.translations.tcHandPraying

      this.churchMinistryInfo[6].title = this.translations.tcSecureReportsTitle
      this.churchMinistryInfo[6].content = this.translations.tcSecureReportsContent
      this.churchMinistryInfo[6].label = this.translations.tcViewMore
      this.churchMinistryInfo[6].img.alt = this.translations.tcHandPraying

      this.churchMinistryInfo[7].title = this.translations.tcOfficerResourcesTitle
      this.churchMinistryInfo[7].content = this.translations.tcOfficerResourcesContent
      this.churchMinistryInfo[7].label = this.translations.tcViewMore
      this.churchMinistryInfo[7].img.alt = this.translations.tcHandPraying
    },
  },
  computed: {
    ...mapGetters({
      announcements: 'announcements/getAnnouncementForState',
      iCanSee: 'user/iCanSee',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      treasuryIsOpen: 'menu/isTreasuryOpen',
      userLogin: 'user/userLogin',
      userStateKey: 'user/userStateKey',
    }),
    announcementAll() {
      let tan =
        !!this.announcements && !!this.announcements.msg_message
          ? this.announcements
          : { msg_message: this.translations.tcNoAnnouncements || 'No Announcements' }
      tan.msg_message = tan.msg_message.replace(/[\n\r]/g, '<br/>')
      return tan
    },
    tiles() {
      return this.churchMinistryInfo.filter((cmi) => cmi.display === true)
    },
  },
  components: {
    appEvent: Event,
    appGraph: Graph,
    BasicDateCard: BasicDateCard,
    campSelector: CampSelect,
    DateCard: DateCard,
    InfoCard: InfoCard,
    NumberCard: NumberCard,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';

.statelanding {
  text-align: center;
}

.church-ministry {
  .page-body {
    margin-top: 68px;

    @include breakpoint(sm) {
      margin-top: 3rem;
    }

    h4 {
      margin-bottom: 36px;
    }
  }

  .btn {
    padding: 12px 35px;
    border: none;
    border-radius: 0;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 22px;
    text-transform: uppercase;
  }

  .btn.btn-primary:hover,
  .btn.btn-primary:active,
  .btn.btn-secondary {
    background-color: #fbc301;
  }

  .btn.btn-primary {
    background-color: #003946;
  }

  .section-1,
  .section-2 {
    margin-bottom: 80px;

    @include breakpoint(sm) {
      margin-bottom: 3rem;
    }

    >.row {
      margin-bottom: 28px;
    }

    .col {
      &:first-of-type {
        padding-right: 23px;
      }

      &:last-of-type {
        padding-left: 23px;
      }

      @include breakpoint(sm) {
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }

  .more {
    width: auto !important;
    margin: 30px 0 30px 0;
  }

  .ann-text {
    color: #747474;
    font-family: 'Lora', serif;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 36px;
    margin-bottom: 42px;
    height: 200px;
    overflow: auto;
  }

  .section-3 {
    margin-bottom: 60px;

    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }

      &:nth-child(2n) {
        padding-left: 23px;
      }
    }

    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
</style>
